<template>
  <div>
    <!-- 个人详情 -->
    <div v-if="recumenAshow">
      <div style="margin: 15px; font-size: 20px">个人详情</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenA.Name"
            label="姓名"
            placeholder="请输入姓名"
          />
          <van-field
            v-model="recumenA.Mobile"
            type="tel"
            label="手机号"
            placeholder="请输入手机号"
          />
          <van-cell title="性别">
            <van-radio-group v-model="recumenA.Sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </van-cell>
          <van-field
            v-model="recumenA.Birthday"
            label="出生日期"
            @click="recumenABirthday = true"
            readonly="readonly"
            placeholder="请输入出生日期"
          />
          <van-popup
            v-model="recumenABirthday"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择出生日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenABirthday = false"
              @confirm="onrecumenABirthday"
            >
            </van-datetime-picker>
          </van-popup>


          <van-field
            v-model="recumenA.NationName"
            label="民族"
            placeholder="请选择所属民族"
            @click="recumenANation = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenANation"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="minzu"
              value-key="Name"
              @cancel="recumenANation = false"
              @confirm="onrecumenANation"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenA.RegCityName"
            label="出生地"
            placeholder="请选择出生地"
            @click="AbilityCode = true"
            readonly="readonly"
          />
          <van-popup
            v-model="AbilityCode"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择出生地"
              :options="NativeCantonCodedata"
              @close="AbilityCode = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="handleChangeb"
            />
          </van-popup>
          <van-field
            v-model="recumenA.LiveCityName"
            label="现住址"
            placeholder="请选择现住址"
            @click="recumenALiveCity = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenALiveCity"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择现住址"
              :options="NativeCantonCodedata"
              @close="recumenALiveCity = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onLiveCity"
            />
          </van-popup>
          <van-field
            v-model="recumenA.EducationName"
            label="学历"
            placeholder="请选择学历"
            @click="recumenAEducation = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenAEducation"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="xveli"
              value-key="Name"
              @cancel="recumenAEducation = false"
              @confirm="onrecumenAEducation"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenA.MarriageName"
            label="婚姻"
            placeholder="请选择婚姻状况"
            @click="recumenAMarriage = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenAMarriage"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="hunyin"
              value-key="Name"
              @cancel="recumenAMarriage = false"
              @confirm="onrecumenAMarriage"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenA.PoliticsName"
            label="政治面貌"
            placeholder="请选择政治面貌"
            @click="recumenAPolitics = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenAPolitics"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="zhengzhi"
              value-key="Name"
              @cancel="recumenAPolitics = false"
              @confirm="onrecumenAPolitics"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenA.WorkYear"
            label="开始工作时间"
            @click="recumenAWorkYear = true"
            readonly="readonly"
            placeholder="请选择开始工作时间"
          />
          <van-popup
            v-model="recumenAWorkYear"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择开始工作时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenAWorkYear = false"
              @confirm="onrecumenAWorkYear"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenA.IdCard"
            type="tel"
            label="身份证号"
            placeholder="请输入身份证号"
          />
          <van-field
            v-model="recumenA.Email"
            label="邮箱"
            placeholder="请输入邮箱"
          />
          <van-field
            v-model="recumenA.WorkStateName"
            label="从业状态"
            placeholder="请选择从业状态"
            @click="recumenAWorkState = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenAWorkState"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="WorkStatefrom"
              value-key="Name"
              @cancel="recumenAWorkState = false"
              @confirm="onrecumenAWorkState"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenA.Evaluation"
            rows="1"
            autosize
            label="自我评价"
            type="textarea"
            placeholder="请输入自我评价"
          />
          <div style="margin:15px">
            <span style="color:#646566; font-size:14px;margin-right:60px;">头像:</span>
            <van-uploader
          v-model="fileList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :deletable="true"
          @delete="shanchu"
        />
          </div>
          <div class="recumenon">
            <van-button round type="info" @click="SaveResume()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 工作经历 -->
    <div v-if="recumenBshow">
      <div style="margin: 15px; font-size: 20px">求职意向</div>
      <div>
        <van-cell-group>
        <van-field
        v-model="recumenB.IndCodeName"
        label="期望行业"
        required
        placeholder="请选择期望行业"
        @click="recumenBIndCode = true"
        readonly="readonly"
      />
      <van-popup v-model="recumenBIndCode" position="bottom" get-container="body">
        <van-cascader
          title="请选择期望行业"
          :options="zhiye"
          @close="recumenBIndCode = false"
          :field-names="{ text: 'Name', value: 'Coding', children: 'Children' }"
          @finish="onrecumenBIndCode"
        />
      </van-popup>
          <van-field
            v-model="recumenB.AbiCodeName"
            label="期望职位"
            placeholder="请选择期望职位"
            @click="recumenBAbiCode = true"
            required
            readonly="readonly"
          />
          <van-popup
            v-model="recumenBAbiCode"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="zhiye"
              value-key="Name"
              @cancel="recumenBAbiCode = false"
              @confirm="onrecumenBAbiCode"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenB.WorkCityName"
            label="工作地点"
            placeholder="请选择工作地点"
            @click="recumenBWorkCityCode = true"
            required
            readonly="readonly"
          />
          <van-popup
            v-model="recumenBWorkCityCode"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择工作地点"
              :options="NativeCantonCodedata"
              @close="recumenBWorkCityCode = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onWorkCityCode"
            />
          </van-popup>
          <van-field
            v-model="recumenB.MinSalary"
            type="digit"
            label="最低月薪"
            required
            placeholder="请输入最低月薪"
          />
          <van-field
            v-model="recumenB.MaxSalary"
            type="digit"
            label="最高月薪"
            required
            placeholder="请输入最高月薪"
          />
          <van-field
            v-model="recumenB.NatureName"
            label="工作性质"
            placeholder="请输入工作性质"
            @click="recumenBNature = true"
            readonly="readonly"
            required
          />
          <van-popup
            v-model="recumenBNature"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="xingzhi"
              value-key="Name"
              @cancel="recumenBNature = false"
              @confirm="onrecumenBNature"
            >
            </van-picker>
          </van-popup>
          <div class="recumenon">
            <van-button round type="info" @click="SaveReJobwant()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 工作经历 -->
    <div v-if="recumenCshow">
      <div style="margin: 15px; font-size: 20px">工作经历</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenC.Company"
            label="公司名称"
            required
            placeholder="请输入公司名称"
          />
          <van-field
            v-model="recumenC.IndCodeName"
            label="行业"
            placeholder="请选择行业"
            @click="recumenCIndCode = true"
            readonly="readonly"
            required
          />
          <van-popup
            v-model="recumenCIndCode"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="hangye"
              value-key="Name"
              @cancel="recumenCIndCode = false"
              @confirm="onrecumenCIndCode"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenC.Position"
            label="职位"
            required
            placeholder="请输入职位"
          />
          <van-field
            v-model="recumenC.WorkCityName"
            label="工作地点"
            required
            placeholder="请选择工作地点"
            @click="recumenCWorkCityCode = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenCWorkCityCode"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择工作地点"
              :options="NativeCantonCodedata"
              @close="recumenCWorkCityCode = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onWorkCityCodes"
            />
          </van-popup>
          <van-field
            v-model="recumenC.InDate"
            label="入职日期"
            @click="recumenCInDate = true"
            readonly="readonly"
            required
            placeholder="请选择入职日期"
          />
          <van-popup
            v-model="recumenCInDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择入职日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenCInDate = false"
              @confirm="onrecumenCInDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenC.LeaveDate"
            label="离职日期"
            @click="recumenCLeaveDate = true"
            readonly="readonly"
            required
            placeholder="请选择离职日期"
          />
          <van-popup
            v-model="recumenCLeaveDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择离职日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenCLeaveDate = false"
              @confirm="onrecumenCLeaveDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenC.LeaveReason"
            rows="1"
            autosize
            required
            label="离职原因"
            type="textarea"
            placeholder="请输入离职原因"
          />
          <van-field
            v-model="recumenC.Salary"
            label="薪资"
            required
            placeholder="请输入薪资"
          />
          <van-field
            v-model="recumenC.Describe"
            rows="1"
            autosize
            required
            label="工作业绩"
            type="textarea"
            placeholder="请输入工作业绩"
          />
          <div class="recumenon">
            <van-button round type="info" @click="SaveReWork()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 教育经历 -->
    <div v-if="recumenDshow">
      <div style="margin: 15px; font-size: 20px">教育经历</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenD.School"
            label="学校名称"
            required
            placeholder="请输入学校名称"
          />
          <van-field
            v-model="recumenD.Profession"
            label="专业"
            required
            placeholder="请输入专业"
          />
          <van-field
            v-model="recumenD.EducationName"
            label="学历"
            required
            placeholder="请选择学历"
            @click="recumenDEducation = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenDEducation"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="xveli"
              value-key="Name"
              @cancel="recumenDEducation = false"
              @confirm="onrecumenDEducation"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenD.InDate"
            label="入学时间"
            @click="recumenDInDate = true"
            readonly="readonly"
            required
            placeholder="请选择入学时间"
          />
          <van-popup
            v-model="recumenDInDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择入学时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenDInDate = false"
              @confirm="onrecumenDInDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenD.EndTime"
            label="毕业时间"
            @click="recumenDEndTime = true"
            readonly="readonly"
            required
            placeholder="请选择毕业时间"
          />
          <van-popup
            v-model="recumenDEndTime"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择毕业时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenDEndTime = false"
              @confirm="onrecumenDEndTime"
            >
            </van-datetime-picker>
          </van-popup>
          <van-cell title="是否统招">
            <van-radio-group
              v-model="recumenD.IsUnified"
              direction="horizontal"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
          <van-cell title="是否211/985">
            <van-radio-group v-model="recumenD.IsFamous" direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
          <div class="recumenon">
            <van-button round type="info" @click="SaveReEducation()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 项目经历 -->
    <div v-if="recumenEshow">
      <div style="margin: 15px; font-size: 20px">项目经历</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenE.Title"
            label="项目名称"
            required
            placeholder="请输入项目名称"
          />
          <van-field
            v-model="recumenE.Company"
            label="公司名称"
            required
            placeholder="请输入公司名称"
          />
          <van-field
            v-model="recumenE.Position"
            label="职位名称"
            required
            placeholder="请输入职位名称"
          />
          <van-field
            v-model="recumenE.StartTime"
            label="开始时间"
            @click="recumenEstart = true"
            readonly="readonly"
            required
            placeholder="请选择开始时间"
          />
          <van-popup
            v-model="recumenEstart"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenEstart = false"
              @confirm="onrecumenEstart"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenE.EndTime"
            label="结束时间"
            @click="recumenEend = true"
            readonly="readonly"
            required
            placeholder="请选择结束时间"
          />
          <van-popup
            v-model="recumenEend"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择结束时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenEend = false"
              @confirm="onrecumenEend"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenE.ProjectDuty"
            rows="1"
            autosize
            label="项目职责"
            required
            type="textarea"
            placeholder="请输入项目职责"
          />
          <van-field
            v-model="recumenE.Achievement"
            rows="1"
            autosize
            label="项目业绩"
            type="textarea"
            required
            placeholder="请输入项目业绩"
          />
          <div class="recumenon">
            <van-button round type="info" @click="SaveReProject()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 培训经历 -->
    <div v-if="recumenFshow">
      <div style="margin: 15px; font-size: 20px">培训经历</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenF.School"
            label="培训机构"
            required
            placeholder="请输入培训机构"
          />
          <van-field
            v-model="recumenF.Course"
            label="课程"
            required
            placeholder="请输入课程"
          />
          <van-field
            v-model="recumenF.StartTime"
            label="开始时间"
            @click="recumenFstart = true"
            readonly="readonly"
            required
            placeholder="请选择开始时间"
          />
          <van-popup
            v-model="recumenFstart"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenFstart = false"
              @confirm="onrecumenFstart"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="recumenF.EndTime"
            label="结束时间"
            @click="recumenFend = true"
            readonly="readonly"
            required
            placeholder="请选择结束时间"
          />
          <van-popup
            v-model="recumenFend"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择结束时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenFend = false"
              @confirm="onrecumenFend"
            >
            </van-datetime-picker>
          </van-popup>
          <div class="recumenon">
            <van-button round type="info" @click="SaveReTrain()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 语言能力 -->
    <div v-if="recumenGshow">
      <div style="margin: 15px; font-size: 20px">语言能力</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenG.LanguageName"
            label="语言类别"
            placeholder="请选择语言类别"
            required
            @click="recumenGcode = true"
            readonly="readonly"
          />
          <van-popup
            v-model="recumenGcode"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="yuzhong"
              value-key="Name"
              @cancel="recumenGcode = false"
              @confirm="onrecumenGcode"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenG.ReadAbilityName"
            label="听说能力"
            placeholder="请选择听说能力"
            @click="ReadAbility = true"
            required
            readonly="readonly"
          />
          <van-popup
            v-model="ReadAbility"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="nengli"
              value-key="Name"
              @cancel="ReadAbility = false"
              @confirm="onReadAbility"
            >
            </van-picker>
          </van-popup>
          <van-field
            v-model="recumenG.WriteAbilityName"
            label="书写能力"
            placeholder="请选择书写能力"
            @click="WriteAbility = true"
            required
            readonly="readonly"
          />
          <van-popup
            v-model="WriteAbility"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="nengli"
              value-key="Name"
              @cancel="WriteAbility = false"
              @confirm="onWriteAbility"
            >
            </van-picker>
          </van-popup>
          <div class="recumenon">
            <van-button round type="info" @click="SaveReLanguage()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 专业技能 -->
    <div v-if="recumenHshow">
      <div style="margin: 15px; font-size: 20px">专业技能</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenH.Name"
            label="技能名称"
            required
            placeholder="请输入技能名称"
          />
          <van-cell required title="使用时长(月)">
            <van-stepper v-model="recumenH.Duration" />
          </van-cell>

          <div class="recumenon">
            <van-button round type="info" @click="SaveReSkill()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
    <!-- 证书信息 -->
    <div v-if="recumenIshow">
      <div style="margin: 15px; font-size: 20px">证书信息</div>
      <div>
        <van-cell-group>
          <van-field
            v-model="recumenI.Name"
            label="证书名称"
            required
            placeholder="请输入证书名称"
          />
          <van-field
            readonly="readonly"
            @click="recumenIdate = true"
            v-model="recumenI.GetDate"
            label="获得日期"
            required
            placeholder="请选择获得日期"
          />
          <van-popup
            v-model="recumenIdate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="year-month"
              title="选择证书获取时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="recumenIdate = false"
              @confirm="onrecumenIdate"
            >
            </van-datetime-picker>
          </van-popup>
          <div class="recumenon">
            <van-button round type="info" @click="SaveReCertificate()"
              >提 交</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>
<script>
import {
  JS_GetMyResume,
  JS_SaveResume,
  JS_SaveReJobwant,
  JS_SaveReWork,
  JS_SaveReEducation,
  JS_SaveReProject,
  JS_SaveReTrain,
  JS_SaveReLanguage,
  JS_SaveReSkill,
  JS_SaveReCertificate,
  GetCodeEntrysList,
} from "@/api/ResumeJS";
import {GetAbilityList} from "@/api/REplat";
import axios from "axios";
import config from "@/config";
import { getToken, setToken } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      // 表单
      recumenA: {
        ReId: this.ReId, //简历Id、
        Name: "", //姓名、
        Sex: "", //性别（1男、2女）、
        Birthday: "", //出生年月，格式：yyyy MM、
        Nation: "", //民族代码（类别：3）、
        IdCard: "", //身份证号、
        RegCity: "", //出生地编码、
        LiveCity: "", //现住地编码、
        Photo: "", //照片、
        Mobile: "", //手机号、
        Email: "", //邮箱、
        Education: "", //学历代码（类别：5）、
        Marriage: "", //婚姻状况代码（类别：4）、
        Politics: "", //政治面貌代码（类别：6）、
        WorkYear: "", //开始工作年份、
        WorkState: "", //从业状态（1 在职看看新机会，2 在职急寻工作机会，3 在职暂无跳槽打算，4 离职正在找工作）、
        Evaluation: "", //自我评价、
        UpdateTime: "", //更新时间、
        Remark: "", //备注
      },
      recumenB: {
        ReJWId: 0, //求职意向Id、
        AbiCode: "", //期望职位（职位类型）编码、
        IndCode: "", //期望行业编码、
        WorkCityCode: "", //工作地点编码、
        MinSalary: "", //最低月薪、
        MaxSalary: "", //最高月薪、
        Nature: "", //工作性质
      },
      recumenC: {
        RWId: 0, //工作经历Id、
        Company: "", //公司名称、
        IndCode: "", //行业编码、
        Position: "", //职位、
        WorkCityCode: "", //工作地点编码、
        InDate: "", //入职日期，格式：yyyy MM、
        LeaveDate: "", //离职日期，格式：yyyy MM、
        LeaveReason: "", //离职原因、
        Salary: "", //薪资、
        Describe: "", //工作业绩、
        Remark: "", //备注
      },
      recumenD: {
        ReEId: 0, //教育经历Id（整型）、
        School: "", //学校名称、
        Profession: "", //专业、
        Education: "", //学历编码（编码类别：10）、
        InDate: "", //入学时间、
        EndTime: "", //毕业时间、
        IsUnified: "", //是否统招（0否，1是）、
        IsFamous: "", //是否211/985（0否，1是）、
        Remark: "", //备注
      },
      recumenE: {
        RePId: 0, //项目经验Id（整型）、
        Title: "", //项目名称、
        Company: "", //公司名称、
        Position: "", //职位名称、
        StartTime: "", //开始时间、
        EndTime: "", //结束时间、
        Describe: "", //项目名称、
        ProjectDuty: "", //项目职责、
        Achievement: "", //项目业绩、
        Remark: "", //备注
      },
      recumenF: {
        ReTId: 0, //培训经历Id、
        School: "", //培训机构、
        Course: "", //课程、
        StartTime: "", //入学时间、
        EndTime: "", //毕业时间、
        Remark: "", //备注
      },
      recumenG: {
        ReLId: 0, //语言能力Id（整型）、
        LanguageCode: "", //语言编码、
        ReadAbility: "", // 听说能力（一般、良好、熟练、精通）、
        WriteAbility: "", //书写能力（一般、良好、熟练、精通）、
        Remark: "", //备注
      },
      recumenH: {
        ReSId: 0, //技能Id、
        Name: "", //名称、
        Duration: "", //使用时长（月）、
        Remark: "", //备注
      },
      recumenI: {
        ReCId: 0, //证书Id（整型）、
        Name: "", //证书名称、
        GetDate: "", //获得日期、
        Remark: "", //备注
      },
      // 表单 显示隐藏
      recumenAshow: false, //简历信息
      recumenBshow: false, //求职意向
      recumenCshow: false, //工作经历
      recumenDshow: false, //教育经历
      recumenEshow: false, //项目经历
      recumenFshow: false, //培训经历
      recumenGshow: false, //培训经历
      recumenHshow: false, //专业技能
      recumenIshow: false, //证书信息
      // 选择器显示与隐藏
      minDate: new Date(1970, 0),
      maxDate: new Date(),
      recumenIdate: false, //证书获取时间
      recumenGcode: false, //语言类别
      ReadAbility: false, //听说能力
      WriteAbility: false, //书写能力
      recumenFstart: false, //培训 开始时间
      recumenFend: false, //培训 结束时间
      recumenEstart: false, //项目 开始时间
      recumenEend: false, //项目 结束时间
      recumenDInDate: false, //教育 开始时间
      recumenDEndTime: false, //教育 结束时间
      recumenDEducation: false, //教育 学历
      recumenCInDate: false, //工作经历 入职
      recumenCLeaveDate: false, //工作经历 离职
      recumenCIndCode: false, //工作经历 行业
      recumenBIndCode: false, //求职意向 行业
      recumenBAbiCode: false, //求职意向 职业
      recumenABirthday: false, //详情 出生日期
      recumenANation: false, //详情 民族
      recumenAEducation: false, //详情 学历
      recumenAMarriage: false, //详情 婚姻
      recumenAPolitics: false, //详情 政治面貌
      recumenAWorkYear: false, //详情 工作开始时间
      AbilityCode: false, //详情 出生地
      recumenALiveCity:false, //详情 现住址 
      recumenBWorkCityCode:false, //求职 工作地点
      recumenCWorkCityCode:false, //工作经历 工作地点
      recumenAWorkState:false, //详情 从业状态
      recumenBNature:false, //求职 工作性质
      // 选择器内容
      sexfrom: ["男", "女"],
      WorkStatefrom: [
        {Coding:1,Name:"在职看看新机会"},
        {Coding:2,Name:"在职急寻工作机会"},
        {Coding:3,Name:"在职暂无跳槽打算"},
        {Coding:4,Name:"离职正在找工作"},
      ],
      NativeCantonCodedata: [],
      NativeCantonCodedataCs: [],
      uploadImgs: [],
      accToken: getToken(), //token
      ReId: 0, //reid
      // 选择器
      xveli: [],
      hunyin: [],
      minzu: [],
      zhengzhi: [],
      hangye: [],
      zhiye: [],
      yuzhong: [],
      diqu: [],
      nengli: [
        { Coding: 1, Name: "一般" },
        { Coding: 2, Name: "良好" },
        { Coding: 3, Name: "熟练" },
        { Coding: 4, Name: "精通" }
      ],
      xingzhi: [
        { Coding: 1, Name: "全职" },
        { Coding: 2, Name: "兼职" },
        { Coding: 3, Name: "实习" }
      ],
      fileList:[],
    };
  },
  created() {
    this.addlist();
    this.GetMyResume();
    this.zidianlist();
  },
  methods: {
    // 进入显示表单判断
    addlist() {
      console.log(this.$route.params.Id);
      console.log(this.$route);
      if (this.$route.params.Id == 2) {
        this.recumenBshow = true;
      }
      if (this.$route.params.Id == 3) {
        this.recumenCshow = true;
      }
      if (this.$route.params.Id == 4) {
        this.recumenDshow = true;
      }
      if (this.$route.params.Id == 5) {
        this.recumenEshow = true;
      }
      if (this.$route.params.Id == 6) {
        this.recumenFshow = true;
      }
      if (this.$route.params.Id == 7) {
        this.recumenGshow = true;
      }
      if (this.$route.params.Id == 8) {
        this.recumenHshow = true;
      }
      if (this.$route.params.Id == 9) {
        this.recumenIshow = true;
      }
      if (this.$route.params.Id == 1) {
        this.recumenAshow = true;
      }
    },
    // 获取基本信息
    GetMyResume() {
      JS_GetMyResume({ accToken: this.accToken }).then((res) => {
        this.recumenA = res.data.data;
        this.ReId = res.data.data.ReId;
      });
    },
    // 保存
    SaveResume() {
      //个人详情
      this.recumenA.accToken = this.accToken;
      JS_SaveResume(this.recumenA).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReJobwant() {
      //求职意向
      if(this.recumenB.IndCodeName == undefined){
        Toast.fail('请选择期待行业');
        return false;
      };
      if(this.recumenB.AbiCodeName == undefined){
        Toast.fail('请选择期待职位');
        return false;
      };
      if(this.recumenB.WorkCityName == undefined){
        Toast.fail('请选择工作地点');
        return false;
      };
      if(this.recumenB.MinSalary == ""){
        Toast.fail('请输入最低月薪');
        return false;
      };
      if(this.recumenB.MaxSalary == ""){
        Toast.fail('请输入最高月薪');
        return false;
      };
      if(this.recumenB.NatureName == undefined){
        Toast.fail('请选择工作性质');
        return false;
      };
      this.recumenB.ReId = this.ReId
      this.recumenB.accToken = this.accToken;
      JS_SaveReJobwant(this.recumenB).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReWork() {
       if(this.recumenC.Company == ""){
        Toast.fail('请输入公司名称');
        return false;
      };
      if(this.recumenC.IndCodeName == undefined){
        Toast.fail('请选择行业');
        return false;
      };
      if(this.recumenC.Position == ""){
        Toast.fail('请输入职位');
        return false;
      };
      if(this.recumenC.WorkCityName == undefined){
        Toast.fail('请选择工作地点');
        return false;
      };
      if(this.recumenC.InDate == ""){
        Toast.fail('请选择入职日期');
        return false;
      };
      if(this.recumenC.LeaveDate == ""){
        Toast.fail('请选择离职日期');
        return false;
      };
      if(this.recumenC.LeaveReason == ""){
        Toast.fail('请输入离职原因');
        return false;
      };
      if(this.recumenC.Salary == ""){
        Toast.fail('请输入薪资');
        return false;
      };
      if(this.recumenC.Describe == ""){
        Toast.fail('请输入工作业绩');
        return false;
      };
      //工作经历
      this.recumenC.ReId = this.ReId
      this.recumenC.accToken = this.accToken;
      JS_SaveReWork(this.recumenC).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReEducation() {
      if(this.recumenD.School == ""){
        Toast.fail('请输入学校名称');
        return false;
      };
      if(this.recumenD.Profession == ""){
        Toast.fail('请输入专业');
        return false;
      };
      if(this.recumenD.EducationName == undefined){
        Toast.fail('请选择学历');
        return false;
      };
      if(this.recumenD.InDate == ""){
        Toast.fail('请选择入学时间');
        return false;
      };
      if(this.recumenD.EndTime == ""){
        Toast.fail('请选择毕业时间');
        return false;
      };
      //教育经历
      this.recumenD.ReId = this.ReId
      this.recumenD.accToken = this.accToken;
      JS_SaveReEducation(this.recumenD).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReProject() {
      if(this.recumenE.Title == ""){
        Toast.fail('请输入项目名称');
        return false;
      };
      if(this.recumenE.Company == ""){
        Toast.fail('请输入公司名称');
        return false;
      };
      if(this.recumenE.Position == ""){
        Toast.fail('请输入职位名称');
        return false;
      };
      if(this.recumenE.StartTime == ""){
        Toast.fail('请选择开始时间');
        return false;
      };
      if(this.recumenE.EndTime == ""){
        Toast.fail('请选择结束时间');
        return false;
      };
      if(this.recumenE.ProjectDuty == ""){
        Toast.fail('请输入项目职责');
        return false;
      };
      if(this.recumenE.Achievement == ""){
        Toast.fail('请输入项目业绩');
        return false;
      };
      //项目经历
      this.recumenE.ReId = this.ReId
      this.recumenE.accToken = this.accToken;
      JS_SaveReProject(this.recumenE).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReTrain() {
      if(this.recumenF.School == ""){
        Toast.fail('请输入培训机构');
        return false;
      };
      if(this.recumenF.Course == ""){
        Toast.fail('请输入课程');
        return false;
      };
      if(this.recumenF.StartTime == ""){
        Toast.fail('请选择开始时间');
        return false;
      };
      if(this.recumenF.EndTime == ""){
        Toast.fail('请选择结束时间');
        return false;
      };
      //培训经历
      this.recumenF.ReId = this.ReId
      this.recumenF.accToken = this.accToken;
      JS_SaveReTrain(this.recumenF).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReLanguage() {
      if(this.recumenG.LanguageName == undefined){
        Toast.fail('请选择语言类别');
        return false;
      };
      if(this.recumenG.ReadAbilityName == undefined){
        Toast.fail('请选择听说能力');
        return false;
      };
      if(this.recumenG.WriteAbilityName == undefined){
        Toast.fail('请选择书写能力');
        return false;
      };
      //语言能力
      this.recumenG.ReId = this.ReId
      this.recumenG.accToken = this.accToken;
      JS_SaveReLanguage(this.recumenG).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReSkill() {
      if(this.recumenH.Name == ""){
        Toast.fail('请输入技能名称');
        return false;
      };
      if(this.recumenH.Duration == ""){
        Toast.fail('请选择使用时长(月)');
        return false;
      };
      //专业技能
      this.recumenH.ReId = this.ReId
      this.recumenH.accToken = this.accToken;
      JS_SaveReSkill(this.recumenH).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    SaveReCertificate() {
      if(this.recumenI.Name == ""){
        Toast.fail('请输入证书名称');
        return false;
      };
      if(this.recumenI.GetDate == ""){
        Toast.fail('请选择获得日期');
        return false;
      };
      //证书
      this.recumenI.ReId = this.ReId
      this.recumenI.accToken = this.accToken;
      JS_SaveReCertificate(this.recumenI).then((res) => {
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          this.$router.push("/list/jobhunter/resume");

          // this.$router.push({path:'resume'});
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 字典查询
    zidianlist: function () {
      // 民族
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.minzu = res.data.data;
      });
      // 婚姻
      GetCodeEntrysList({ kind: "4" }).then((res) => {
        this.hunyin = res.data.data;
      });
      // 学历
      GetCodeEntrysList({ kind: "5" }).then((res) => {
        this.xveli = res.data.data;
      });
      // 政治面貌
      GetCodeEntrysList({ kind: "6" }).then((res) => {
        this.zhengzhi = res.data.data;
      });
      // 行业
      GetCodeEntrysList({ kind: "202" }).then((res) => {
        this.hangye = res.data.data;
      });
      // 职业
      GetAbilityList().then((res) => {
        this.zhiye = res.data.data;
      });
      // 语种
      GetCodeEntrysList({ kind: "52" }).then((res) => {
        this.yuzhong = res.data.data;
      });
      // 地区
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [],
          Citysx = [],
          Citysq = [],
          Citysqp = [],
          list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                text: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, text: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, text: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
        console.log(this.NativeCantonCodedata);
        this.NativeCantonCodedataCs = Citys;
      });
    },
    handleChangea: function (value) {
      this.resumeFormsA.RegCity = value[value.length - 1];
    },
    handleChangeb: function (value) {
      var RegCity = this.addressfrom(value)
      this.recumenA.RegCity = RegCity[0];
      this.recumenA.RegCityName = RegCity[1];
      this.AbilityCode = false;
    },
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value)
      this.recumenA.LiveCity = RegCity[0];
      this.recumenA.LiveCityName = RegCity[1];
      this.recumenALiveCity = false;
    },
    onWorkCityCode: function (value) {
      var RegCity = this.addressfrom(value)
      this.recumenB.WorkCityCode = RegCity[0];
      this.recumenB.WorkCityName = RegCity[1];
      this.recumenBWorkCityCode = false;
    },
    onWorkCityCodes: function (value) {
      var RegCity = this.addressfrom(value)
      this.recumenC.WorkCityCode = RegCity[0];
      this.recumenC.WorkCityName = RegCity[1];
      this.recumenCWorkCityCode = false;
    },
    handleChangec: function (value) {
      this.resumeFormsC.WorkCityCode = value[value.length - 1];
    },
    // 选择器事件
    onReadAbility(val) {
      //听说能力 确认
      this.recumenG.ReadAbility = val.Coding;
      this.recumenG.ReadAbilityName = val.Name;
      this.ReadAbility = false;
    },
    //详情 从业状态
    onrecumenAWorkState(val){
      this.recumenA.WorkState = val.Coding;
      this.recumenA.WorkStateName = val.Name;
      this.recumenAWorkState = false;
    },
    onrecumenAEducation(val) {
      //详情 学历
      this.recumenA.Education = val.Coding;
      this.recumenA.EducationName = val.Name;
      this.recumenAEducation = false;
    },
    onrecumenAMarriage(val) {
      //详情 婚姻
      this.recumenA.Marriage = val.Coding;
      this.recumenA.MarriageName = val.Name;
      this.recumenAMarriage = false;
    },
    onrecumenAPolitics(val) {
      //详情 政治面貌
      this.recumenA.Politics = val.Coding;
      this.recumenA.PoliticsName = val.Name;
      this.recumenAPolitics = false;
    },
    onrecumenANation(val) {
      //详情 民族
      this.recumenA.Nation = val.Coding;
      this.recumenA.NationName = val.Name;
      this.recumenANation = false;
    },
    onrecumenDEducation(val) {
      // 教育 学历
      this.recumenD.Education = val.Coding;
      this.recumenD.EducationName = val.Name;
      this.recumenDEducation = false;
    },
    onrecumenCIndCode(val) {
      // 工作经历 行业
      this.recumenC.IndCode = val.Coding;
      this.recumenC.IndCodeName = val.Name;
      this.recumenCIndCode = false;
    },
    onrecumenBAbiCode(val) {
      // 求职意向 职位
      this.recumenB.AbiCode = val.Coding;
      this.recumenB.AbiCodeName = val.Name;
      this.recumenBAbiCode = false;
    },
    onrecumenBIndCode(val) {
      // 求职意向 行业
      console.log(val);
      if(val.selectedOptions.length == 1){
        this.recumenB.IndCode = val.selectedOptions[0].Coding;
        this.recumenB.IndCodeName = val.selectedOptions[0].Name;
      }else{
        this.recumenB.IndCode = val.selectedOptions[1].Coding;
        this.recumenB.IndCodeName = val.selectedOptions[1].Name;
      }
      this.recumenBIndCode = false;
    },
    onWriteAbility(val) {
      //书写能力 确认
      this.recumenG.WriteAbility = val.Coding;
      this.recumenG.WriteAbilityName = val.Name;
      this.WriteAbility = false;
    },
    onrecumenGcode(val) {
      //语言类别 确认
      this.recumenG.LanguageCode = val.Coding;
      this.recumenG.LanguageName = val.Name;
      this.recumenGcode = false;
    },
    onrecumenIdate(val) {
      //证书时间 确认
      this.recumenI.GetDate = this.dateformats(val);
      this.recumenIdate = false;
    },
    onrecumenFstart(val) {
      //培训 开始时间 确认
      this.recumenF.StartTime = this.dateformats(val);
      this.recumenFstart = false;
    },
    onrecumenFend(val) {
      //培训 结束时间 确认
      this.recumenF.EndTime = this.dateformats(val);
      this.recumenFend = false;
    },
    onrecumenEstart(val) {
      //项目 开始时间 确认
      this.recumenE.StartTime = this.dateformats(val);
      this.recumenEstart = false;
    },
    onrecumenAWorkYear(val) {
      //详情 工作开始时间 确认
      this.recumenA.WorkYear = this.dateformats(val);
      this.recumenAWorkYear = false;
    },
    onrecumenEend(val) {
      //项目 结束时间 确认
      this.recumenE.EndTime = this.dateformats(val);
      this.recumenEend = false;
    },
    onrecumenDInDate(val) {
      //教育 入学时间 确认
      this.recumenD.InDate = this.dateformats(val);
      this.recumenDInDate = false;
    },
    onrecumenDEndTime(val) {
      //教育 毕业时间 确认
      this.recumenD.EndTime = this.dateformats(val);
      this.recumenDEndTime = false;
    },
    onrecumenCInDate(val) {
      //工作经历 入职时间 确认
      this.recumenC.InDate = this.dateformats(val);
      this.recumenCInDate = false;
    },
    onrecumenCLeaveDate(val) {
      //工作经历 离职时间 确认
      this.recumenC.LeaveDate = this.dateformats(val);
      this.recumenCLeaveDate = false;
    },
    onrecumenABirthday(val) {
      //个人详情 出生日期
      this.recumenA.Birthday = this.dateformat(val);
      this.recumenABirthday = false;
    },
    onrecumenBNature(val){
      console.log(val);
      this.recumenB.Nature = val.Coding;
      this.recumenB.NatureName = val.Name;
      this.recumenBNature = false;
    },
    // 图片上传
    afterRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
        this.recumenA.Photo = res.data.picurl;
      });
    },
    // 图片删除
    shanchu: function () {
      console.log(123);
      this.dataform.Thumb = "";
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    addressfrom(value){
      var a,b,c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + ',' + b.text + ',' + c.text;
      var address = [addressCode,addressName]
      return address
    },
    dateformats(val) {
      // 时间格式化事件 yy-mm
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      return `${year}-${month}`;
    },
  },
};
</script>
<style>
.recumenon {
}
.recumenon button {
  width: 90%;
  margin: 30px 5%;
}
</style>