import request from '@/utils/request'

// 企业注册
export function RE_Register(data) {
    return request({
        url: 'REPlat/RE_Register',
        method: 'post',
        data
    })
}
// 企业登录
export function CheckStaff(data) {
    return request({
        url: 'REPlat/CheckStaff',
        method: 'post',
        data
    })
}
// 获取简历分页列表
export function RE_GetResumePage(params) {
    return request({
        url: 'Resume/RE_GetResumePage',
        method: 'get',
        params
    })
}
// 获取职位信息列表
export function RE_GetJobPage(params) {
    return request({
        url: 'Job/RE_GetJobPage',
        method: 'get',
        params
    })
}
// 保存职位
export function RE_SaveJob(data) {
    return request({
        url: 'Job/RE_SaveJob',
        method: 'post',
        data
    })
}
// 获取职业类型信息列表
export function GetAbilityList(params) {
    return request({
        url: 'Ability/GetAbilityList',
        method: 'get',
        params
    })
}
// 获取简历详情
export function RE_GetResume(params) {
    return request({
        url: 'Resume/RE_GetResume',
        method: 'get',
        params
    })
}
// 获取指定简历的求职意向列表
export function RE_GetReJobwantList(params) {
    return request({
        url: 'Resume/RE_GetReJobwantList',
        method: 'get',
        params
    })
}
// 获取指定简历的工作经历列表
export function RE_GetReWorkList(params) {
    return request({
        url: 'Resume/RE_GetReWorkList',
        method: 'get',
        params
    })
}
// 获取指定简历的教育经历列表
export function RE_GetReEducationList(params) {
    return request({
        url: 'Resume/RE_GetReEducationList',
        method: 'get',
        params
    })
}
// 获取指定简历的项目经历列表
export function RE_GetReProjectList(params) {
    return request({
        url: 'Resume/RE_GetReProjectList',
        method: 'get',
        params
    })
}
// 获取指定简历的培训经历列表
export function RE_GetReTrainList(params) {
    return request({
        url: 'Resume/RE_GetReTrainList',
        method: 'get',
        params
    })
}
// 获取指定简历的语言能力列表
export function RE_GetReLanguageList(params) {
    return request({
        url: 'Resume/RE_GetReLanguageList',
        method: 'get',
        params
    })
}
// 获取指定简历的专业技能列表
export function RE_GetReSkillList(params) {
    return request({
        url: 'Resume/RE_GetReSkillList',
        method: 'get',
        params
    })
}
// 获取指定简历的证书列表
export function RE_GetReCertificateList(params) {
    return request({
        url: 'Resume/RE_GetReCertificateList',
        method: 'get',
        params
    })
}
// 获取指定职位的简历投递信息列表
export function RE_GetJobReDeliverList(params) {
    return request({
        url: 'Redeliver/RE_GetJobReDeliverList',
        method: 'get',
        params
    })
}
// 加入招聘流程
export function RE_AddCallList(data) {
    return request({
        url: 'Redeliver/RE_AddCallList',
        method: 'post',
        data
    })
}
// 淘汰简历
export function RE_Eliminate(data) {
    return request({
        url: 'Redeliver/RE_Eliminate',
        method: 'post',
        data
    })
}
// 获取企业详情
export function RE_GetMyEnterprise(params) {
    return request({
        url: 'Enterprise/RE_GetMyEnterprise',
        method: 'get',
        params
    })
}
// 更改密码
export function ChangePwd(data) {
    return request({
        url: 'REPlat/ChangePwd',
        method: 'post',
        data
    })
}
// 获取简历投递信息分页列表
export function RE_GetReDeliverPage(params) {
    return request({
        url: 'Redeliver/RE_GetReDeliverPage',
        method: 'get',
        params
    })
}
// 保存企业信息
export function RE_SaveEnterprise(data) {
    return request({
        url: 'Enterprise/RE_SaveEnterprise',
        method: 'post',
        data
    })
}
// 获取候选人分页列表
export function RE_GetCoResumePage(params) {
    return request({
        url: 'Process/RE_GetCoResumePage',
        method: 'get',
        params
    })
}
// 获取招聘流程阶段列表
export function RE_GetRecruitStageList(params) {
    return request({
        url: 'Process/RE_GetRecruitStageList',
        method: 'get',
        params
    })
}
// 延期入职
export function DelayEntry(data) {
    return request({
        url: 'Process/DelayEntry',
        method: 'post',
        data
    })
}
// 入职
export function Entry(data) {
    return request({
        url: 'Process/Entry',
        method: 'post',
        data
    })
}
// 不合格
export function SetUnqualified(data) {
    return request({
        url: 'Process/SetUnqualified',
        method: 'post',
        data
    })
}
// 面试
export function RCInterview(data) {
    return request({
        url: 'Process/RCInterview',
        method: 'post',
        data
    })
}
// 淘汰候选人
export function Eliminate(data) {
    return request({
        url: 'Process/Eliminate',
        method: 'post',
        data
    })
}
// 录用
export function Admission(data) {
    return request({
        url: 'Process/Admission',
        method: 'post',
        data
    })
}